
import { Form } from "vee-validate";
import {
  reactive,
  provide,
  computed,
  onMounted,
  onUpdated,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import InputBase from "@/components/Base/InputBase.vue";
import TableEditableHeaders from "@/components/Base/TableEditableHeaders.vue";
import store from "@/store";
import AddressesTE from "@/components/entities/tables-editable/AddressesTE.vue";
import { useRoute, useRouter } from "vue-router";
import AccountingPlanSelect from "@/components/catalogs-select/AccountingPlanSelect.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CompanyGroupSelect from "@/components/catalogs-select/CompanyGroupSelect.vue";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";
///import Loading from "@/components/Loading.vue";
import Avatar from "@/components/avatar/avatar.vue";
import BillingSeriesTE from "@/components/companies/BillingSeriesTE.vue";
import { ElNotification } from "element-plus/es";
import CurrencySelect from "@/components/catalogs-select/CurrencySelect.vue";

export default {
  name: "CompanyCreateView",
  components: {
    CurrencySelect,
    BillingSeriesTE,
    Avatar,
    EntitySelect,
    CompanyGroupSelect,
    //Loading,
    AccountingPlanSelect,
    AddressesTE,
    Form,
    InputBase,
    TableEditableHeaders,
  },
  props: {
    makeLast: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const isFirst = ref(true);
    const logo = ref(undefined) as any;
    const logodoc = ref(undefined) as any;
    // activities log store
    const recently_activities = computed(
      () => store.getters.RecentlyActivities
    );
    const apData = computed(() => store.getters.acPlans);
    const addressData = computed(() => store.getters.Addresses);
    const billingSerieData = computed(() => store.getters.BillingSeries);
    let company = ref({
      name: "",
      cif: "",
      website: "",
      logo: null,
      lang: "",
      currency_id: "",
      logodoc: null,
      accounting_plan_id: [],
      company_group_id: 0,
      entity_id: "",
      fiscal_data: "",
      code: "",
      addresses: [],
    });

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canUpdate = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "companies: update") !==
        -1
      );
    });
    watch(
      () => company,
      (first, second) => {
        if (first && !isFirst.value) {
          store.commit("addDataActivity", {
            id: `company_${route.params.id}`,
            tag: "general",
            data: first,
            name: first.value.name,
          });
          isFirst.value = false;
        }
      },
      { deep: true }
    );

    const catalogs = reactive({
      companiesGroup: [],
      loadingCompaniesGroup: false,
      entities: [],
      loadingEntities: false,
    });
    const fetchCompaniesGroup = async () => {
      catalogs.loadingCompaniesGroup = true;
      const { data } = await ApiService.get("api/companiesGroups/lists");
      catalogs.companiesGroup = data.companiesGroups;
      catalogs.loadingCompaniesGroup = false;
    };
    fetchCompaniesGroup();

    const fetchEntities = async () => {
      catalogs.loadingEntities = true;
      const { data } = await ApiService.get("api/entities/lists");
      catalogs.entities = data.entities;
      catalogs.loadingEntities = false;
    };
    fetchEntities();

    // eslint-disable-next-line @typescript-eslint/ban-types
    let tableDataOffices = computed(() => store.getters.getSelectedOffices);
    // eslint-disable-next-line @typescript-eslint/ban-types
    const tableDataAP: Object[] = reactive([]);
    const tableHeadersOffices = reactive([
      {
        hidden: false,
        label: "iname",
        header: "name",
        type: "text",
        required: true,
      },
      {
        hidden: false,
        label: "iis_main",
        header: "is_main",
        type: "checkbox",
        required: false,
      },
      {
        hidden: false,
        label: "iremarks",
        header: "remarks",
        type: "text",
        required: false,
      },
    ]);
    const tableHeadersACPlans = reactive([
      {
        hidden: false,
        label: "iname",
        header: "name",
        type: "text",
        required: true,
      },
    ]);

    watch(
      () => tableDataOffices.value,
      (first, second) => {
        if (first && !isFirst.value) {
          store.commit("setSelectedOffices", first);
        } else {
          isFirst.value = false;
        }
      },
      { deep: true }
    );
    const addRowDataToOffices = () => {
      store.commit("setItemSelectedOffices", {
        company_id: "",
        name: "",
        is_main: false,
        logo: null,
        remarks: "",
        communications: [],
      });
    };
    const removeRowDataToOffices = (index) => {
      ApiService.delete(
        `/api/offices/${tableDataOffices.value[index]["id"]}`
      ).then(({ data }) => {
        store.commit("deleteItemSelectedOffices", index);
      });
    };

    const deleteAp = (i) => {
      apData.value.forEach(function (value, index) {
        if (index === i) {
          apData.value.splice(i, 1);
        }
      });
    };

    provide("officesData", tableDataOffices);
    provide("acPlanData", tableDataAP);
    provide("officesHeaders", tableHeadersOffices);
    provide("acHeaders", tableHeadersACPlans);

    const onSubmit = async () => {
      const acData = [] as any;
      const adData = [] as any;
      addressData.value.forEach(function (aData) {
        const api = [] as any;
        aData.address_types.forEach(function (apiVal) {
          api.push({
            address_type_id: apiVal.id === undefined ? apiVal : apiVal.id,
          });
        });
        if (aData.id) {
          adData.push({
            id: aData.id,
            street: aData.street,
            number: aData.number,
            floor_number: aData.floor_number,
            door: aData.door,
            location_id: aData.location_id,
            zip_code: aData.zip_code,
            latitude: aData.latitude,
            longitude: aData.longitude,
            address_types: api,
          });
        } else {
          adData.push({
            street: aData.street,
            number: aData.number,
            floor_number: aData.floor_number,
            door: aData.door,
            location_id: aData.location_id,
            zip_code: aData.zip_code,
            latitude: aData.latitude,
            longitude: aData.longitude,
            address_types: api,
          });
        }
      });
      apData.value.forEach(function (value) {
        acData.push(value.id);
      });

      const data = {
        id: route.params.id,
        name: company.value.name,
        cif: company.value.cif,
        website: company.value.website,
        logo: company.value.logo,
        logodoc: company.value.logodoc,
        lang: company.value.lang,
        currency_id: company.value.currency_id,
        accounting_plan_id: JSON.stringify(company.value.accounting_plan_id),
        addresses: JSON.stringify(adData),
        offices: JSON.stringify(tableDataOffices.value),
        billing_series: JSON.stringify(billingSerieData.value.map((x) => x.id)),
        company_group_id: company.value.company_group_id,
        entity_id: company.value.entity_id,
        fiscal_data: company.value.fiscal_data,
        code: company.value.code,
      };
      const formData = new FormData();
      if (route.params.id !== undefined) {
        formData.append("id", route.params.id.toString());
      }
      formData.append("name", company.value.name);
      formData.append("cif", company.value.cif);
      formData.append("website", company.value.website);
      formData.append("fiscal_data", company.value.fiscal_data);
      formData.append("code", company.value.code);
      formData.append("lang", company.value.lang);
      formData.append("currency_id", company.value.currency_id);
      formData.append("logo", logo.value);
      formData.append("logodoc", logodoc.value);
      formData.append(
        "accounting_plan_id",
        JSON.stringify(company.value.accounting_plan_id)
      );
      formData.append("addresses", JSON.stringify(adData));
      formData.append("offices", JSON.stringify(tableDataOffices.value));
      formData.append(
        "billing_series",
        JSON.stringify(billingSerieData.value.map((x) => x.id))
      );
      formData.append(
        "company_group_id",
        company.value.company_group_id.toString()
      );
      formData.append("entity_id", company.value.entity_id);
      if (props.makeLast) {
        emit("submit", data);
      } else {
        if (route.params.id) {
          const { data: companyData } = await ApiService.postAttach(
            "api/companies/update/" + route.params.id,
            formData
          );
          const address = [] as any;
          store.commit("emptyAddresses");
          companyData.addresses.forEach(function (addresVal) {
            const ati = [] as any;
            addresVal.address_types.forEach(function (atiVal) {
              ati.push(atiVal);
            });
            address.push({
              id: addresVal.id,
              street: addresVal.street,
              number: addresVal.number,
              floor_number: addresVal.floor_number,
              door: addresVal.door,
              location_id: addresVal.location_id,
              zip_code: addresVal.zip_code,
              latitude: addresVal.latitude,
              longitude: addresVal.longitude,
              address_types: ati,
            });
          });
          store.commit("setAddresses", address);
          store.commit("setsaveForm", false);
        } else {
          const { data: companyData } = await ApiService.postAttach(
            "api/companies",
            formData
          );
          const address = [] as any;
          companyData.addresses.forEach(function (addresVal) {
            const ati = [] as any;
            addresVal.address_types.forEach(function (atiVal) {
              ati.push(atiVal);
            });
            address.push({
              id: addresVal.id,
              street: addresVal.street,
              number: addresVal.number,
              floor_number: addresVal.floor_number,
              door: addresVal.door,
              location_id: addresVal.location_id,
              zip_code: addresVal.zip_code,
              latitude: addresVal.latitude,
              longitude: addresVal.longitude,
              address_types: ati,
            });
          });
          store.commit("emptyAddresses");
          store.commit("setAddresses", address);
          store.commit("setsaveForm", false);
        }
      }
      await router.push({ name: "companiesIndex" });
    };
    const createAddress = () => {
      store.commit("createAddresses", {
        id: 0,
        entity_id: undefined,
      });
    };
    const removeAddress = (i) => {
      addressData.value.forEach(function (value, index) {
        if (value.id === undefined) {
          addressData.value.splice(i, 1);
        } else {
          if (index === i) {
            addressData.value.splice(i, 1);
          }
        }
      });
    };

    const changeImage = (event) => {
      logo.value = event[0];
    };

    const removeImage = () => {
      logo.value = "";
    };

    const changeImageDoc = (event) => {
      logodoc.value = event[0];
    };

    const removeImageDoc = () => {
      logodoc.value = "";
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(function () {
      console.clear();
      store.commit("setLoadingStatus", true);
      setCurrentPageBreadcrumbs(
        route.params.id !== undefined ? "companiesUpdate" : "companiesCreate",
        [{ route: "/companies/index", label: "companies" }]
      );
      if (route.params.id) {
        store.commit("emptyAddresses");
        store.commit("setBillingSerie", []);
        ApiService.get("/api/companies/" + route.params.id).then(function (
          response
        ) {
          company.value = response.data;
          const ap = [] as any;
          const address = [] as any;
          response.data.addresses.forEach(function (addresVal) {
            const ati = [] as any;
            addresVal.address_types.forEach(function (atiVal) {
              ati.push(atiVal);
            });
            address.push({
              id: addresVal.id,
              street: addresVal.street,
              number: addresVal.number,
              floor_number: addresVal.floor_number,
              door: addresVal.door,
              location_id: addresVal.location_id,
              zip_code: addresVal.zip_code,
              latitude: addresVal.latitude,
              longitude: addresVal.longitude,
              address_types: ati,
            });
          });
          store.commit("setAddresses", address);
          response.data.accounting_plans.forEach(function (value) {
            ap.push(value.id);
          });
          company.value.accounting_plan_id = ap;
          store.commit("setSelectedOffices", response.data.offices);
          store.commit("setBillingSerie", response.data.billing_series);
          store.commit("setLoadingStatus", false);
        });
      } else {
        store.commit("emptyAddresses");
        store.commit("setSelectedOffices", []);
        store.commit("setBillingSerie", []);
        store.commit("setLoadingStatus", false);
      }
    });

    const createBillingSerie = () => {
      store.commit("createBillingSerie", {
        id: 0,
        code: "",
        type: "",
        observations: "",
      });
    };
    const deleteBillingSerie = (i) => {
      billingSerieData.value.splice(i, 1);
      /*const dataBillingSerie = [] as any;
      billingSerieData.value.forEach(function (value) {
        dataBillingSerie.push(value.id);
      });*/
    };
    const saveBillingSerie = (data) => {
      const arrayBillingSerie = [] as any;
      const dataBillingSerie = [] as any;
      store.commit("setLoadingStatus", true);
      ApiService.get("/api/billing_serie/" + data).then(function (response) {
        billingSerieData.value.forEach(function (value) {
          dataBillingSerie.push(value.id);
          if (value.id === data) {
            arrayBillingSerie.push(response.data);
          } else {
            arrayBillingSerie.push(value);
          }
        });
        store.commit("setBillingSerie", arrayBillingSerie);
        store.commit("setLoadingStatus", false);
        /*ApiService.post("/api/entities/" + route.params.id + "/sectors", {
          sector_ids: 1,
        }).then(function (response) {
          ElNotification({
            title: "Success",
            message: response.data.message,
            type: "success",
          });
          store.commit("setLoadingStatus", false);
        });*/
      });
    };

    return {
      tableDataOffices,
      addressData,
      billingSerieData,
      company,
      logo,
      logodoc,
      changeImage,
      removeImage,
      changeImageDoc,
      removeImageDoc,
      removeAddress,
      catalogs,
      createAddress,
      onSubmit,
      deleteAp,
      addRowDataToOffices,
      removeRowDataToOffices,
      canUpdate,
      createBillingSerie,
      deleteBillingSerie,
      saveBillingSerie,
    };
  },
};


import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "CompanyGroupSelect",
  props: {
    companyId: {
      type: Number,
      default: undefined,
    },
    companyGroup: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:companyId"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const companyGData = computed(() => store.getters.CompaniesListG);
    const companyData = ref([]);
    const inputCompany = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isCompanyRequired = (value) => {
      if (props.required && !value) {
        return t("rcompany");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "company",
      isCompanyRequired
    );

    const getCompany = () => {
      if (companyData.value.length === 0) {
        store.commit("setLoading", false);
        ApiService.get("/api/companiesGroups/lists").then(({ data }) => {
          if (!props.companyGroup) {
            companyData.value = data.companies_groups;
            inputCompany.value.list = data.companies_groups;
            inputCompany.value.options = data.companies_groups;
          } else {
            companyData.value = companyGData.value;
            inputCompany.value.list = companyGData.value;
            inputCompany.value.options = companyGData.value;
          }
        });
      } else {
        inputCompany.value.list = companyData.value.slice();
        inputCompany.value.options = companyData.value.slice();
      }
      const index = inputCompany.value.options.findIndex(
        (x) => x.name === "Todos"
      );
      if (props.multiselect) {
        if (index === -1) {
          inputCompany.value.options.splice(0, 0, {
            id: 0,
            name: "Todos",
          });
        }
      } else if (index !== -1) {
        inputCompany.value.options.splice(0, 1);
      }
    };
    const selectCompany = (query) => {
      if (query !== "") {
        store.commit("setLoading", false);
        ApiService.query(`/api/companiesGroups/lists`, {
          params: { per_page: 25, name: query },
        }).then(({ data }) => {
          if (!props.companyGroup) {
            inputCompany.value.list = data.companies_groups;
            inputCompany.value.options = data.companies_groups;
          } else {
            companyData.value = companyGData.value;
            inputCompany.value.list = companyGData.value;
            inputCompany.value.options = companyGData.value;
          }
        });
      } else {
        inputCompany.value.options = companyGData;
      }
    };

    watch(
      () => props.companyId,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        getCompany();
      }, 3000);
    });

    return {
      element_id,
      inputCompany,
      errorMessage,
      companyData,
      getCompany,
      selectCompany,
    };
  },
};


import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "AccountingPlanSelect",
  props: {
    accounting_plan_id: {
      type: Array,
      default: () => [],
    },
    accounting_plans_data: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:ac_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref(props.accounting_plan_id);
    const accountingPlanData = computed(() => store.getters.AccountingPlan);
    const inputElements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isPlanRequired = (value) => {
      if (props.required && (!value || value.length == 0)) {
        return t("raccountingplan");
      }
      return true;
    };

    const { errorMessage, value } = useField("accountingPlan", isPlanRequired);
    const getElements = () => {
      inputElements.value.list = [];
      inputElements.value.options = [];
      if (
        accountingPlanData.value.length == 0 &&
        props.accounting_plans_data.length == 0
      ) {
        store.commit("setLoading", false);
        store.commit("setLoadingStatus", true);
        ApiService.query(`/api/accountingPlans/lists`, {
          params: { per_page: 25 },
        }).then(({ data }) => {
          store.commit("setAccountingPlan", data.accounting_plan);
          inputElements.value.list = data.accounting_plan;
          inputElements.value.options = data.accounting_plan;
          store.commit("setLoadingStatus", false);
        });
      }

      if (props.accounting_plans_data.length > 0) {
        inputElements.value.list = props.accounting_plans_data;
        inputElements.value.options = props.accounting_plans_data;
      } else {
        inputElements.value.list = accountingPlanData.value;
        inputElements.value.options = accountingPlanData.value;
      }

      store.commit("setLoadingStatus", false);
    };
    const selectElements = (query) => {
      if (query !== "") {
        store.commit("setLoading", false);
        ApiService.query(`/api/accountingPlans/lists`, {
          params: { per_page: 25, name: query },
        }).then(({ data }) => {
          data.accounting_plan.forEach((item) => {
            store.commit("addAccountingPlan", item);
          });
          inputElements.value.list = data.accounting_plan;
          inputElements.value.options = data.accounting_plan;
        });
      } else {
        inputElements.value.options = accountingPlanData.value;
      }
    };

    watch(
      () => props.accounting_plan_id,
      (first) => {
        if (first != undefined) {
          element_id.value = first;
          value.value = first;
        }
      }
    );

    watch(
      () => props.accounting_plans_data,
      (first) => {
        if (first?.length > 0) {
          inputElements.value.list = first;
          inputElements.value.options = first;
        } else {
          getElements();
        }
      }
    );

    onMounted(() => {
      setTimeout(function () {
        getElements();
        console.log(props.accounting_plan_id);
        value.value = props.accounting_plan_id;
      }, 3000);
    });

    return {
      element_id,
      inputElements,
      errorMessage,
      selectElements,
    };
  },
};

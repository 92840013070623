
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import store from "@/store";

export default {
  name: "BillingSeriesTE",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "saveElement"],

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const dataTable = computed(() => store.getters.BillingSeries);
    const selector = ref([]);

    const headers = ref([
      { label: "icode", prop: "code" },
      { label: "itype", prop: "type" },
      { label: "iobservations", prop: "observations" },
    ]);
    const headerTable = ref(headers.value);
    const addElement = (value) => {
      if (dataTable.value.find((o) => o.id === value.id) === undefined) {
        store.commit("createBillingSerie", {
          id: value.id,
          code: value.code,
          type: value.billing_series_type.name,
          observations: value.observations,
        });
      }
    };
    const fieldSelector = () => {
      if (selector.value.find((o) => o === 0) === 0) {
        elements.value.options.forEach(function (value) {
          addElement(value);
          selector.value = [];
        });
      } else {
        selector.value.forEach(function (value) {
          let data = elements.value.options.find((o) => o.id === value);
          addElement(data);
          selector.value = [];
        });
      }
    };

    const getData = () => {
      //
    };
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });

    watch(
      () => props.tableData,
      (first, second) => {
        if (first) {
          store.commit("setBillingSerie", props.tableData);
        }
      },
      { deep: true }
    );

    onMounted(() => {
      getData();
      ApiService.get("/api/billing_serie/lists?per_page=100").then(function (
        response
      ) {
        elements.value.options = response.data.billing_serie;
      });
    });

    return {
      fieldSelector,
      selector,
      elements,
      dataTable,
      loading,
      headers,
      headerTable,
      contactSelected,
    };
  },
};

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!!$props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["form-label fs-6 fw-bolder text-darker", { required: $props.required }]),
          for: $props.id
        }, _toDisplayString(_ctx.$t(`${$props.label}`)), 11, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      id: $props.id,
      value: $props.modelValue,
      type: "text",
      name: $props.id,
      autocomplete: "off",
      class: _normalizeClass($setup.classes),
      onInput: _cache[0] || (_cache[0] = (event) => _ctx.$emit('update:modelValue', event.target.value))
    }, null, 42, _hoisted_2)
  ], 64))
}

import { computed } from "vue";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    class: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(
      () =>
        `${
          props.class
            ? props.class
            : "form-control form-control-app custom-height"
        }`
    );

    return { classes };
  },
};


import InputBase from "./InputBase.vue";
import InputFile from "./InputFile.vue";
import { ref, inject } from "vue";

export default {
  name: "TableEditableHeaders",
  components: { InputBase, InputFile },
  props: {
    data: {
      type: String,
      default: "",
    },
    headers: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement"],
  setup(props) {
    const dataT = inject(`${props.data}`);
    // eslint-disable-next-line @typescript-eslint/ban-types
    const headersT = inject(`${props.headers}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const headersToShow = ref(headersT.map((x, i) => i));

    return { dataT, headersT, headersToShow };
  },
};

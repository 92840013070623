<template>
  <div
    class="image-input image-input-outline"
    :class="{ 'w-125px': !small, 'h-125px': !small }"
    data-kt-image-input="true"
    style="background-image: url(media/defaults/blank.png)"
    :style="small ? 'width: 60px; height: 60px;' : ''"
  >
    <div
      v-show="!image.url && !defaultImage"
      class="image-input-wrapper"
      :class="{ 'w-125px': !small, 'h-125px': !small }"
      style="background-image: url(media/defaults/blank.png)"
      :style="small ? 'width: 60px; height: 60px;' : ''"
    />
    <img
      v-show="image.url || defaultImage"
      :src="image.url || defaultImage"
      alt="image"
      style="height: 100%; width: auto; max-width: 100%"
    />
    <label v-if="label" class="form-label fs-6 fw-bolder text-dark">
      {{ $t(`${label}`) }}
    </label>
    <label
      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
      data-kt-image-input-action="change"
      data-bs-toggle="tooltip"
      title="Change"
      style="display: block"
    >
      <i class="bi bi-pencil-fill fs-7"></i>
      <input
        type="file"
        name="avatar"
        accept=".png, .jpg, .jpeg"
        @change="previewImage"
      />
      <input type="hidden" name="avatar_remove" />
    </label>
    <span
      class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
      data-kt-image-input-action="remove"
      data-bs-toggle="tooltip"
      title="Remove"
      @click="removeImage"
    >
      <i class="bi bi-x fs-2"></i>
    </span>
  </div>
</template>

<script>
import { computed, reactive } from "vue";

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const image = reactive({
      url: "",
      file: null,
    });
    const previewImage = (event) => {
      image.file = event.target.files[0];
      image.url = URL.createObjectURL(image.file);
      emit("change", image.file);
    };

    const removeImage = () => {
      image.file = null;
      image.url = "";
      emit("change", image.file);
    };
    const defaultImage = computed(() => {
      if (props.img) {
        return `${process.env.VUE_APP_API_URL}${props.img}`;
      }
      return false;
    });

    return { previewImage, removeImage, image, defaultImage };
  },
};
</script>
